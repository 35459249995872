<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>版权声明</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>版权声明</h3>
      <p>
        一、凡本网站注明“来源：人民交通出版社股份有限公司”的所有作品，版权均属人民交通出版社股份有限公司所有，未经本网授权不得转载、摘编或利用其它方式使用上述作品。如经本网站授权使用作品的，应在授权范围内使用，并注明“来源：人民交通出版社股份有限公司”。违反上述声明者，本网站将追究其相关法律责任。
      </p>

      <p>
        二、本网站未标有“来源：人民交通出版社股份有限公司”的文章，均属转载文章。本网站标明转载文章的出处，并保留转载文章在原刊载媒体上的署名形式，但本网站对转载文章的版权归属和权利瑕疵情况不承担核实责任。如任何单位或个人认为本网站转载的文章涉嫌侵犯其合法权益，应及时向本网站提出书面意见并提供相关证明材料和理由，本网站在收到上述文件后将采取相应措施。
      </p>

      <p>
        三、对于任何因直接或间接采用、转载本网站提供的信息造成的损失，本网站均不承担责任。对于其他单位或个人授权本网发布的作品，禁止任何媒体、网站或个人在未经书面授权的情况下转载使用。
      </p>

      <p>
        四
        、擅自使用人民交通出版社股份有限公司名义转载不规范来源的信息、版权不明的资讯，或盗用人民交通出版社股份有限公司名义发布信息，本网将依法追究其法律责任。
      </p>

      <p>
        五、凡以任何方式登录本网站或直接、间接使用本网站资料者，视为自愿接受本声明的约束。
      </p>

      <p>
        六、本免责声明以及其修改权、更新权及最终解释权均属人民交通出版社股份有限公司所有。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
